<template>
    <div class="page">

        <Head />
        <Sidebar />
        <div class="body-main">
            <breadInfo />
            <div class="container flex-1 flex">
                <div class="con-l flex-v">
                    <div class="xm-s xm-s1 flex-1 flex-v">
                        <h6 class="bt">GT200自增压爆震燃机项目</h6>
                        <div class="box flex-1">
                            <div class="imgbox">
                                <img src="../../assets/images/fadongji.png" class="pic" alt="" />
                            </div>
                            <div class="item item1">
                                <img src="../../assets/images/xm_18.png" class="icon" alt="" />
                                <div class="info">
                                    <p class="fz1">燃油箱液位</p>
                                    <p class="fz2"><i>{{ turbineData.liquid }}</i>mL</p>
                                </div>
                                <img src="../../assets/images/xm_26.png" class="line" alt="" />
                            </div>
                            <div class="item item2">
                                <img src="../../assets/images/xm_18.png" class="icon" alt="" />
                                <div class="info">
                                    <p class="fz1">电机温度</p>
                                    <p class="fz2"><i>{{ turbineData.temp1 }}</i>℃</p>
                                </div>
                                <img src="../../assets/images/xm_27.png" class="line" alt="" />
                            </div>
                            <div class="item item3">
                                <img src="../../assets/images/xm_18.png" class="icon" alt="" />
                                <div class="info">
                                    <p class="fz1">环境温度</p>
                                    <p class="fz2"><i>{{ turbineData.temp2 }}</i>℃</p>
                                </div>
                                <img src="../../assets/images/xm_28.png" class="line" alt="" />
                            </div>
                            <div class="item item4">
                                <img src="../../assets/images/xm_18.png" class="icon" alt="" />
                                <div class="info">
                                    <p class="fz1">电机转速</p>
                                    <p class="fz2"><i>{{ turbineData.speed }}</i>r/min</p>
                                </div>
                                <img src="../../assets/images/xm_29.png" class="line" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="xm-s xm-s2">
                        <div class="pub-tt m15">
                            <h5 class="bt">
                                <img src="../../assets/images/xm_11.png" class="ico" alt="" />燃机信息
                            </h5>
                            <div class="dix"></div>
                        </div>
                        <div class="chart" ref="chart"></div>
                    </div>
                </div>
                <div class="con-r flex-v">
                    <div class="xm-s xm-s3">
                        <div class="pub-tt m15">
                            <h5 class="bt">
                                <img src="../../assets/images/xm_01.png" class="ico" alt="" />燃机信息
                            </h5>
                            <div class="dix"></div>
                        </div>
                        <ul class="list">
                            <li>
                                <img src="../../assets/images/xm_02.png" class="icon" alt="" />
                                <div class="info">
                                    <h6 class="fz">型号</h6>
                                    <p class="num num1">GT200h</p>
                                </div>
                            </li>
                            <li>
                                <img src="../../assets/images/xm_03.png" class="icon" alt="" />
                                <div class="info">
                                    <h6 class="fz">燃烧状态</h6>
                                    <p class="num num2">H2</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="xm-s xm-s4">
                        <div class="pub-tt m15">
                            <h5 class="bt">
                                <img src="../../assets/images/xm_04.png" class="ico" alt="" />视频监控
                            </h5>
                            <div class="dix"></div>
                        </div>
                        <div class="imgbox">
                            <div id="video-player" class="divVide img img_full"></div>
                        </div>
                    </div>
                    <div class="xm-s xm-s5 flex-1 flex-v">
                        <div class="pub-tt m15">
                            <h5 class="bt">
                                <img src="../../assets/images/xm_06.png" class="ico" alt="" />运行状态监测
                            </h5>
                            <div class="dix"></div>
                        </div>
                        <vue-seamless-scroll class="scrollbox flex-1 scrollybg" :class-option="defaultOption">
                            <ul class="list">
                                <!-- <template v-for="item in 2">
                                    <li>
                                        <img src="../../assets/images/xm_07.png" class="icon" alt="" />
                                        <div class="info">
                                            <p class="fz1 ellipsis">这里是运行状态！</p>
                                            <p class="fz2 ellipsis">
                                                控制台提供了一套设备告警的可视化功能
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="../../assets/images/xm_08.png" class="icon" alt="" />
                                        <div class="info">
                                            <p class="fz1 ellipsis">这里是运行状态！</p>
                                            <p class="fz2 ellipsis">
                                                控制台提供了一套设备告警的可视化功能
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="../../assets/images/xm_09.png" class="icon" alt="" />
                                        <div class="info">
                                            <p class="fz1 ellipsis">这里是运行状态！</p>
                                            <p class="fz2 ellipsis">
                                                控制台提供了一套设备告警的可视化功能
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="../../assets/images/xm_10.png" class="icon" alt="" />
                                        <div class="info">
                                            <p class="fz1 ellipsis">这里是运行状态！</p>
                                            <p class="fz2 ellipsis">
                                                控制台提供了一套设备告警的可视化功能
                                            </p>
                                        </div>
                                    </li>
                                </template> -->
                                <li v-for="(value, key) in rjDataList">
                                    <div class="info">
                                        <p class="rjdata">{{ key }}:</p>
                                        <P>{{ value }}</P>
                                    </div>

                                </li>

                            </ul>
                        </vue-seamless-scroll>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Head from "../../components/head.vue";
import Sidebar from "../../components/sidebar.vue";
import breadInfo from "../../components/breadInfo.vue";
import Player from "xgplayer"; // 这是他的一个方法
import ReconnectingWebSocket from "reconnecting-websocket";
import FlvPlayer from "xgplayer-flv"; // 这个是搭配上面哪个使用的
import "xgplayer/dist/index.min.css"; // 这个是播放器的样式 ，可以在当前页面使用，也可以放全局
let Echarts = require("echarts/lib/echarts");
export default {
    data() {
        return {
            turbineData: {
                temp1: '',
                temp2: '',
                liquid: '',
                speed: ''
            },
            webData: [],
            rjDataList: {
                "电机温度1": "",
                "电机温度2": "",
                "电机温度3": "",
                "电机温度4": "",
                "电机温度5": "",
                "电机温度6": "",
                "排气温度1": "",
                "排气温度2": "",
                "排气温度3": "",
                "燃油流量": "",
                "燃油箱液位": "",
                "环境压力": "",
                "燃油泵后压力": "",
                "后轴承压力": "",
                "燃机振动": "",
                "电机振动": "",
                "丙烷压力": "",
                "环境温度": "",
                "工作阀后压力": "",
                "起动阀后压力": "",
                "电机转速1": "",
                "电机转速2": "",
                "电机转速3": "",
                "燃油泵功率": "",
                "抽油泵功率": "",
                "变频器设定频率": "",
                "变频器反馈频率": "",
                "变频器输出频率": "",
                "变频器运行": "",
                "燃油泵运行": "",
                "抽油泵运行": "",
                "点火器上电": "",
                "点火信号触发": "",
                "滑油系统运行": "",
                "起动阀打开": "",
                "工作阀1打开": "",
                "工作阀2打开": "",
                "排油阀打开": "",
                "清吹阀打开": "",
                "丙烷阀门打开": "",
                "燃油吹除阀打开": "",
                "后轴承冷却阀打开": "",
            }
        };
    },
    components: {
        Head,
        Sidebar,
        breadInfo,
    },
    created() { },
    mounted() {
        this.init();
        this.initVideo();
        this.createWebsocket();
        window.addEventListener("resize", () => {
            this.chart.resize();
        });
        this.startAutoScroll();
    },
    watch: {},
    computed: {
        defaultOption() {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: this.webData.length, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
            };
        },
    },
    methods: {
        formatFreeShippingInfo(param) {
            return param == 'true'
                ? "是"
                : param == 'false'
                    ? "否"
                    : param;
        },

        startAutoScroll() {
            setInterval(() => {
                this.scrollList();
            }, 2000); // Adjust the interval as needed
        },
        scrollList() {
            const scrollList = this.$refs.scrollList;
            if (scrollList) {
                const firstItem = scrollList.querySelector("li");
                if (firstItem) {
                    scrollList.appendChild(firstItem.cloneNode(true));
                    scrollList.removeChild(firstItem);
                }
            }
        },

        initVideo() {
            new Player({
                id: "video-player", // 对应<div :id='this.playList[0].id'></div>
                isLive: true, // 是否是直播，
                plugins: [FlvPlayer], // 视频播放的方式
                url: "https://v1.ntvgbs.cn:4443/rtp/00C24E31.live.flv", // 地址
                autoplay: true, //
                lang: "zh-cn", // 是否自动播放，
                autoplayMuted: true, //是否自动静音自动播放
                screenShot: true, //是否使用截图插件
                rotate: false, //是否使用旋转插件
                download: false, //是否使下载按钮
                pip: false, //是否使用画中画插件
                mini: false, //是否启用mini小窗插件
                playbackRate: [0.5, 0.75, 1, 1.5, 2], //倍速插件显示列表
            });
        },
        // 创建 websocket 链接
        createWebsocket() {
            const httpURL = "43.143.218.144:9084/websocket";
            let token = sessionStorage.getItem("userToken");
            this.websocket = new ReconnectingWebSocket(
                `ws://${httpURL}?Authorization=${token}`
            );
            // 连接发生错误的回调方法
            this.websocket.onerror = this.websocketOnerror;
            // 连接成功建立的回调方法
            this.websocket.onopen = this.websocketOnopen;
            // 接收到消息的回调方法
            this.websocket.onmessage = this.websocketOnmessage;
            // 连接关闭的回调方法
            this.websocket.onclose = this.websocketOnclose;
            // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
            this.websocket.onbeforeunload = this.websocketOnbeforeunload;
        },
        // 连接发生错误的回调方法
        websocketOnerror() {
            console.log("连接发生错误的回调方法");
        },
        // 连接成功建立的回调方法
        websocketOnopen() {
            console.log("连接成功建立的回调方法");
        },
        // 接收到消息的回调方法
        websocketOnmessage(event) {
            const data = JSON.parse(event.data);
            if (data.method == "activeValue") {
                this.turbineData.liquid = parseFloat(data.channelValues['microTurbine0/FuelTankLevel']).toFixed(2)
                this.turbineData.speed = parseFloat(data.channelValues['microTurbine0/MotorSpeed2']).toFixed(2)
                this.turbineData.temp2 = parseFloat(data.channelValues['microTurbine0/AmbientTemperture']).toFixed(2)
                this.turbineData.temp1 = parseFloat(data.channelValues['microTurbine0/ExhaustTemperature1']).toFixed(2)

                this.rjDataList['电机温度1'] = data.channelValues['microTurbine0/MotorTemperature1']
                this.rjDataList['电机温度2'] = data.channelValues['microTurbine0/MotorTemperature2']
                this.rjDataList['电机温度3'] = data.channelValues['microTurbine0/MotorTemperature3']
                this.rjDataList['电机温度4'] = data.channelValues['microTurbine0/MotorTemperature4']
                this.rjDataList['电机温度5'] = data.channelValues['microTurbine0/MotorTemperature5']
                this.rjDataList['电机温度6'] = data.channelValues['microTurbine0/MotorTemperature6']
                this.rjDataList['排气温度1'] = data.channelValues['microTurbine0/ExhaustTemperature1']
                this.rjDataList['排气温度2'] = data.channelValues['microTurbine0/ExhaustTemperature2']
                this.rjDataList['排气温度3'] = data.channelValues['microTurbine0/ExhaustTemperature3']
                this.rjDataList['燃油流量'] = data.channelValues['microTurbine0/FuelFlow']
                this.rjDataList['燃油箱液位'] = data.channelValues['microTurbine0/FuelTankLevel']
                this.rjDataList['环境压力'] = data.channelValues['microTurbine0/AmbientPressure']
                this.rjDataList['燃油泵后压力'] = data.channelValues['microTurbine0/FuelPumpRearPressure']
                this.rjDataList['后轴承压力'] = data.channelValues['microTurbine0/RearBearingPressure']
                this.rjDataList['燃机振动'] = data.channelValues['microTurbine0/GasTurbineVibration']
                this.rjDataList['电机振动'] = data.channelValues['microTurbine0/MotorVibration']
                this.rjDataList['丙烷压力'] = data.channelValues['microTurbine0/PropanePressure']
                this.rjDataList['环境温度'] = data.channelValues['microTurbine0/AmbientTemperture']
                this.rjDataList['工作阀后压力'] = data.channelValues['microTurbine0/PressureAfterWorkingValue']
                this.rjDataList['起动阀后压力'] = data.channelValues['PressureAfterStartingValue']
                this.rjDataList['电机转速1'] = data.channelValues['microTurbine0/MotorSpeed1']
                this.rjDataList['电机转速2'] = data.channelValues['microTurbine0/MotorSpeed2']
                this.rjDataList['电机转速3'] = data.channelValues['microTurbine0/MotorSpeed3']
                this.rjDataList['燃油泵功率'] = data.channelValues['microTurbine0/FuelPumpPower']
                this.rjDataList['抽油泵功率'] = data.channelValues['microTurbine0/OilExtractionPump']
                this.rjDataList['变频器设定频率'] = data.channelValues['microTurbine0/FrequencySettingOfFrequencyConverter']
                this.rjDataList['变频器反馈频率'] = data.channelValues['microTurbine0/FrequencyConverterFeedbackFrequency']
                this.rjDataList['变频器输出频率'] = data.channelValues['microTurbine0/FrequencyConverterOutputFrequency']

                this.rjDataList['变频器运行'] = this.formatFreeShippingInfo(data.channelValues['microTurbine0/FrequencyConverterOperation'])
                this.rjDataList['燃油泵运行'] = this.formatFreeShippingInfo(data.channelValues['microTurbine0/FuelPumpOperation'])
                this.rjDataList['抽油泵运行'] = this.formatFreeShippingInfo(data.channelValues['microTurbine0/OilExtractionPumpOperation'])
                this.rjDataList['点火器上电'] = this.formatFreeShippingInfo(data.channelValues['microTurbine0/IgniterPoweredOn'])
                this.rjDataList['点火信号触发'] = this.formatFreeShippingInfo(data.channelValues['microTurbine0/IgnitionSignalTriggered'])
                this.rjDataList['滑油系统运行'] = this.formatFreeShippingInfo(data.channelValues['microTurbine0/OilSystemOperation'])

                this.rjDataList['起动阀打开'] = this.formatFreeShippingInfo(data.channelValues['microTurbine0/StartValueOpen'])
                this.rjDataList['工作阀1打开'] = this.formatFreeShippingInfo(data.channelValues['microTurbine0/WorkingValue1Opens'])
                this.rjDataList['工作阀2打开'] = this.formatFreeShippingInfo(data.channelValues['microTurbine0/WorkingValue2Opens'])
                this.rjDataList['排油阀打开'] = this.formatFreeShippingInfo(data.channelValues['microTurbine0/OilDrainValueOpen'])
                this.rjDataList['清吹阀打开'] = this.formatFreeShippingInfo(data.channelValues['microTurbine0/PurgeValueOpen'])
                this.rjDataList['丙烷阀门打开'] = this.formatFreeShippingInfo(data.channelValues['microTurbine0/PropaneValueOpen'])
                this.rjDataList['燃油吹除阀打开'] = this.formatFreeShippingInfo(data.channelValues['microTurbine0/FuelPurgeValueOpen'])
                this.rjDataList['后轴承冷却阀打开'] = this.formatFreeShippingInfo(data.channelValues['microTurbine0/RearBearingCoolingValueOpen'])





            }
        },
        // 连接关闭的回调方法
        websocketOnclose() {
            console.log("连接关闭的回调方法");
        },
        // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常
        websocketOnbeforeunload() {
            this.closeWebSocket();
            console.log(
                "监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常"
            );
        },
        // 关闭WebSocket连接
        closeWebSocket() {
            this.websocket.close();
        },
        init() {
            this.chart = Echarts.init(this.$refs.chart);
            let option = {
                tooltip: {
                    trigger: "axis",
                    confine: true,
                },
                legend: {
                    icon: "rect",
                    right: "0%",
                    top: "2%",
                    itemWidth: 20,
                    itemHeight: 2,
                    itemGap: 15,
                    textStyle: {
                        color: "#ccd9e5",
                        fontSize: 12,
                    },
                },
                grid: {
                    left: 10,
                    right: 10,
                    bottom: 0,
                    top: 40,
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: ["14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00"],
                        boundaryGap: true,
                        axisPointer: {
                            type: "",
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#9da9c0", //更改坐标轴文字颜色
                                fontSize: 12, //更改坐标轴文字大小
                                lineHeight: 12,
                            },
                            rotate: 0,
                            interval: 0,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,.16)", //更改坐标轴颜色
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: ["#e6d3c6"],
                                width: 1,
                                type: "solid",
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "",
                        position: "left",
                        min: 0,
                        // max: 8,
                        // interval: 2,
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#7989a6", //更改坐标轴文字颜色
                                fontSize: 12, //更改坐标轴文字大小
                            },
                            //formatter: '{value}%'
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#fff", //更改坐标轴颜色
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ["rgba(255,255,255,.16)"],
                                width: 1,
                                type: "dashed",
                            },
                        },
                    },
                ],

                series: [
                    {
                        name: "燃油流量",
                        type: "line",
                        data: [5, 3, 6, 4, 7, 2, 3, 2],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 4,
                        itemStyle: {
                            normal: {
                                color: "#ae5da1",
                                borderColor: "#ae5da1",
                                borderWidth: 1,
                                lineStyle: {
                                    color: "#ae5da1",
                                },
                                areaStyle: {
                                    color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                        {
                                            offset: 0,
                                            color: "rgba(174,93,161,0)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(174,93,161,.5)",
                                        },
                                    ]),
                                },
                            },
                        },
                    },
                    {
                        name: "电机转速",
                        type: "line",
                        data: [2, 5, 2, 5, 3, 6, 4, 4],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 4,
                        itemStyle: {
                            normal: {
                                color: "#00a0e9",
                                borderColor: "#00a0e9",
                                borderWidth: 1,
                                lineStyle: {
                                    color: "#00a0e9",
                                },
                                areaStyle: {
                                    color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                        {
                                            offset: 0,
                                            color: "rgba(0,160,233,0)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(0,160,233,.5)",
                                        },
                                    ]),
                                },
                            },
                        },
                    },
                    {
                        name: "排气温度",
                        type: "line",
                        data: [6, 5, 2, 4, 5, 8, 6, 5],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 4,
                        itemStyle: {
                            normal: {
                                color: "#25c05a",
                                borderColor: "#25c05a",
                                borderWidth: 1,
                                lineStyle: {
                                    color: "#25c05a",
                                },
                                areaStyle: {
                                    color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                        {
                                            offset: 0,
                                            color: "rgba(37,192,90,0)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(37,192,90,.5)",
                                        },
                                    ]),
                                },
                            },
                        },
                    },
                ],
            };
            this.chart.setOption(option);
        },
    },
    destroyed() {
        this.closeWebSocket();
    },
};
</script>

<style scoped>
.con-l {
    flex: 1;
    overflow: hidden;
}

.con-r {
    width: 380px;
    margin-left: 10px;
}

.xm-s {
    padding: 0 20px 20px;
}

.pub-tt {
    margin: 0 0 15px;
}

.xm-s1 {
    margin-bottom: 10px;
    background: url(../../assets/images/xm_12.png) no-repeat center;
    background-size: 100% 100%;
}

.xm-s1 .bt {
    width: 522px;
    height: 70px;
    background: url(../../assets/images/xm_24.png) no-repeat center;
    background-size: 100% 100%;
    font-size: 24px;
    color: #fff;
    line-height: 50px;
    margin: 0 auto;
    text-align: center;
}

.xm-s1 .box {}

.xm-s1 .imgbox {
    width: 85%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../../assets/images/xm_25.jpg) no-repeat center;
    background-size: cover;
}

.xm-s1 .pic {
    height: 90%;
    width: auto;
    display: block;
}

.xm-s1 .item {
    width: 260px;
    height: 110px;
    background: url(../../assets/images/xm_19.png) no-repeat center;
    background-size: 100% 100%;
    padding: 25px;
    position: absolute;
    display: flex;
    align-items: center;
}

.xm-s1 .item .icon {
    width: 65px;
    margin-right: 8px;
}

.info {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.rjdata {
    margin-left: 40px;
    width: 125px;
}


.xm-s1 .item .fz1 {
    font-size: 14px;
    color: #ccd9e5;
}


.xm-s1 .item .fz2 {
    font-size: 14px;
    color: #ccd9e5;
    margin-top: 4px;
}

.xm-s1 .item .fz2 i {
    font-size: 26px;
    font-family: "Digital";
    color: #fff;
    margin-right: 6px;
}

.xm-s1 .item .line {
    position: absolute;
    max-width: initial;
}

.xm-s1 .item1 {
    top: 50%;
    left: 50%;
    margin-left: -630px;
    margin-top: -320px;
}

.xm-s1 .item1 .line {
    right: 10px;
    transform: translate(100%, 0);
    width: 200px;
    top: 55px;
    height: 150px;
}

.xm-s1 .item2 {
    top: 50%;
    left: 50%;
    margin-left: -630px;
    margin-top: -30px;
}

.xm-s1 .item2 .line {
    right: 10px;
    transform: translate(100%, 0);
    width: 130px;
    bottom: 50px;
}

.xm-s1 .item3 {
    top: 50%;
    left: 50%;
    margin-left: 130px;
    margin-top: -320px;
}

.xm-s1 .item3 .line {
    left: 10px;
    transform: translate(-100%, 0);
    width: 240px;
    top: 55px;
    height: 60px;
}

.xm-s1 .item4 {
    top: 50%;
    left: 50%;
    margin-left: 130px;
    margin-top: -150px;
}

.xm-s1 .item4 .line {
    left: 10px;
    transform: translate(-100%, 0);
    width: 150px;
    bottom: 50px;
}

.xm-s2 {
    background: url(../../assets/images/xm_13.png) no-repeat center;
    background-size: 100% 100%;
}

.xm-s2 .pub-tt {
    margin-bottom: 5px;
}

.xm-s2 .chart {
    height: 200px;
}

.xm-s3 {
    margin-bottom: 10px;
    background: url(../../assets/images/xm_14.png) no-repeat center;
    background-size: 100% 100%;
}

.xm-s3 .list {
    display: flex;
    justify-content: space-between;
    
}

.xm-s3 .list li {
    display: flex;
    align-items: center;
    min-width: 40%;
}

.xm-s3 .list .icon {
    width: 50px;
    height: 50px;
    margin-right: 12px;
}

.xm-s3 .list .info {
    flex: 1;
    overflow: hidden;
}

.xm-s3 .list .fz {
    font-size: 14px;
    color: #ccd9e5;
}

.xm-s3 .list .num {
    font-size: 26px;
    font-family: "Digital";
    margin-top: 5px;
}

.xm-s3 .list .num1 {
    color: #ae2cff;
}

.xm-s4 {
    margin-bottom: 10px;
    background: url(../../assets/images/xm_15.png) no-repeat center;
    background-size: 100% 100%;
}

.xm-s4 .imgbox {
    height: 17vh;
}

.xm-s5 {
    background: url(../../assets/images/xm_16.png) no-repeat center;
    background-size: 100% 100%;
}

.xm-s5 .list {}

.xm-s5 .list li {
    padding: 10px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #2e2f3b;
}

.xm-s5 .list .icon {
    width: 44px;
    height: 44px;
    margin-right: 10px;
}

.xm-s5 .list .info {
    flex: 1;
    overflow: hidden;
}

.xm-s5 .list .fz1 {
    font-size: 14px;
    color: #ccd9e5;
}

.xm-s5 .list .fz2 {
    font-size: 13px;
    color: #7989a6;
    margin-top: 4px;
}

.xm-s5 .scrollbox {
    width: calc(100% + 10px);
    padding-right: 10px;
}


.scroll-list {
  list-style: none;
  padding: 0;
  margin: 0;
  transition: all 0.3s;
}

.scroll-list li {
  margin: 10px;
}

.xm-s5 .list li:last-child {
    border: none;
}

.divVide {
    width: 100% !important;
    position: relative !important;
    height: 100% !important;
}
</style>
